/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import env from '@beam-australia/react-env';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useNavigate } from 'react-router-dom';
import { getEventoById } from '@/store/eventos/eventosActions';
import { getPriceDescription, obtenerParte, parseCopete, parseFecha, parseHora } from '@/components/utils';

const EventoInscripcion3: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { eventoId } = useParams();
  const dataEvento = useAppSelector(state => state.eventos?.itemIdData);
  const { userInfo } = useAppSelector(state => state.user);
  const params = new URLSearchParams(window.location.search);
  const idtransaccion = params.get('id');
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (eventoId) {
      dispatch(getEventoById({ id: parseInt(eventoId.split('-')[0]) }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (dataEvento) {
      setIsDataLoaded(true);
    }
  }, [dataEvento]);

  useEffect(() => {
    if (isDataLoaded && dataEvento?.Modalidad == 'Online') {
      // Evitar duplicados si ya existe el script
      if (document.getElementById('facebook-pixel-script')) {
        return;
      }

      // Crear el script principal
      const script = document.createElement('script');
      script.id = 'facebook-pixel-script';
      script.async = true;
      script.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '1258912372104014');
        fbq('track', 'Purchase', {value: 450.00, currency: 'USD'});
      `;
      document.head.appendChild(script);

      // Limpiar el script al desmontar
      return () => {
        document.head.removeChild(script);
      };
    }
  }, [isDataLoaded]);

  return (
    <>
      {/* Breadcrumb */}
      <section className="container d-none d-lg-block mt-2">
        <div className="row">
          <div className="col-12">
            <div className="border-top py-2">
              <nav style={{}} aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Inicio</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/actividades">Actividades</a>
                  </li>

                  <li className="breadcrumb-item active" aria-current="page">
                    {dataEvento ? dataEvento.Titulo : ''}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/* Header */}
      {dataEvento && (
        <>
          <header className="container my-2 my-md-3">
            <div className="row g-4">
              <div className="col-md-12">
                <h1 className="display-4 mb-4">{dataEvento.Titulo}</h1>
                <a className="btn btn-outline-secondary icon-calendar me-1 mb-2" href="#" role="button">
                  {dataEvento.Fecha ? parseFecha(dataEvento.Fecha.toString()) : ''}
                </a>
                <a
                  className={
                    dataEvento.Modalidad === 'Online'
                      ? 'btn btn-outline-secondary   icon-video  me-1 mb-2'
                      : dataEvento.Modalidad === 'Presencial'
                      ? 'btn btn-outline-secondary   icon-presencial  me-1 mb-2'
                      : dataEvento.Modalidad === 'Video'
                      ? 'btn btn-outline-secondary   icon-video  me-1 mb-2'
                      : 'btn btn-outline-secondary   '
                  }
                  href="#"
                  role="button">
                  {dataEvento.Modalidad === 'Video' ? 'Zoom' : dataEvento.Modalidad}
                </a>
                {dataEvento.Oradores &&
                  dataEvento.Oradores.split(';').map(dataOrador => (
                    <a className="btn btn-outline-secondary icon-speaker me-1 mb-2" href="#" role="button">
                      {dataOrador}
                    </a>
                  ))}
                <p className="lead my-4" dangerouslySetInnerHTML={{ __html: parseCopete(dataEvento) }} />
                <hr />
              </div>
            </div>
          </header>
          <section className="container mb-5">
            <div className="row g-4">
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <h6 className="text-secondary">Actividad</h6>
                    <h2 className="card-title">{dataEvento ? dataEvento.Titulo : ''}</h2>
                    <hr />
                    <ul className="evento-info">
                      {(!dataEvento.evento_sociales || (dataEvento.evento_sociales && dataEvento.evento_sociales.length == 0)) &&
                        dataEvento.Modalidad !== 'Online' && (
                          <li className="icon-schedule d-flex align-items-center my-1">
                            {/* 17-09-2022 de 10:00am a 5:00pm */}
                            {dataEvento.Fecha ? parseFecha(dataEvento.Fecha.toString()) : ''}
                          </li>
                        )}

                      {dataEvento.evento_sociales && dataEvento.evento_sociales.length > 0 && (
                        <>
                          <li className="icon-schedule d-flex align-items-center my-1">
                            {/* 17-09-2022 de 10:00am a 5:00pm */}
                            {dataEvento.evento_sociales[0].Fecha ? (
                              <>
                                {' '}
                                Fecha y Hora de Inicio <br />{' '}
                                {parseFecha(dataEvento.evento_sociales[0].Fecha.toString()) +
                                  ' ' +
                                  parseHora(dataEvento.evento_sociales[0].Fecha.toString())}{' '}
                              </>
                            ) : (
                              ''
                            )}
                          </li>
                          <li className="icon-schedule d-flex align-items-center my-1">
                            {/* 17-09-2022 de 10:00am a 5:00pm */}
                            {dataEvento.evento_sociales[0].FechaFin ? (
                              <>
                                {' '}
                                Fecha y Hora de Fin <br />{' '}
                                {parseFecha(dataEvento.evento_sociales[0].FechaFin.toString()) +
                                  ' ' +
                                  parseHora(dataEvento.evento_sociales[0].FechaFin.toString())}{' '}
                              </>
                            ) : (
                              ''
                            )}
                          </li>
                        </>
                      )}
                      <li className="icon-video d-flex align-items-center my-1">{dataEvento.Modalidad}</li>
                      <li className="icon-currency d-flex align-items-center my-1">
                        {obtenerParte(getPriceDescription(dataEvento))}
                      </li>
                      {dataEvento.Oradores &&
                        dataEvento.Oradores.split(';').map(dataOrador => (
                          <li className="icon-speaker d-flex align-items-center my-1">{dataOrador}</li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="card p-2">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-8">
                        <h6 className="text-primary">Inscripción a la actividad</h6>
                      </div>
                      <div className="col-4 text-end text-muted">
                        <h6>Paso 3 de 3</h6>
                      </div>
                    </div>
                  </div>
                  {idtransaccion ? (
                    <div className="card-body py-5">
                      <h1 className="mb-5 text-primary">Gracias por inscribirte.</h1>
                      <p>
                        Gracias Su compra fue confirmada correctamente. Numero de transacción <strong>{idtransaccion}</strong>
                      </p>
                      <p>En unas horas nos comunicaremos con usted. Por cualquier consulta comuniquese a</p>
                      <p>
                        <a href="mailto:organizacionhp@gmail.com">organizacionhp@gmail.com</a>
                      </p>
                    </div>
                  ) : (
                    <div className="card-body py-5">
                      <h1 className="mb-5 text-primary">Gracias por inscribirte.</h1>
                      <p>Gracias, le recordamos que le enviamos un mail con los datos para realizar el pago.</p>
                      <p>
                        Por favor, luego de realizar un pago recuerde enviar los datos del mismo e imagen del comprobante a{' '}
                        <a href="mailto:organizacionhp@gmail.com">organizacionhp@gmail.com</a>.
                      </p>
                      <p>
                        <strong>Nota:</strong> Recuerde que cuenta con 7 días para realizar el pago de su inscripción.
                      </p>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-12 mb-3 d-grid">
                      <a href="/" className="btn btn-primary">
                        Finalizar
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default EventoInscripcion3;
